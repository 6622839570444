import "core-js/modules/es.array.iterator.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.find.js";
// libraries
import bowser from 'bowser';
import Svg4Everybody from 'svg4everybody';
import objectFitImages from 'object-fit-images';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import InertiaPlugin from 'gsap/InertiaPlugin';
import Draggable from 'gsap/Draggable';
import ScrollToPlugin from 'gsap/ScrollToPlugin'; // core components

import Application from './core/application';
import Dispatcher from './core/dispatcher';
import Controllers from './core/controllers'; // controllers

import navigation from './controllers/nav'; // import home from '@controllers/home';
// components

import Slider from './components/slider';
import Carousel from './components/carousel';
import Fields from './components/fields';
import Form from './components/form'; // reveals components
// import Reveals from '@components/reveals';
// import RevealDefault from '@components/reveals/default';
// register gsap plugins to avoid build / tree shaking issues

gsap.registerPlugin(ScrollTrigger, InertiaPlugin, Draggable, ScrollToPlugin);
Application.use(Dispatcher);
Application.use(Controllers, {
  navigation: navigation // home,

}); // Application.use(Reveals, {
//     default:   RevealDefault,
// });
// color palette
// https://coolors.co/247ba0-70c1b3-b2dbbf-f3ffbd-ff1654

/*----------------------------------------------------*/
// Application

/*----------------------------------------------------*/

var App = new Application({
  debug: __DEV__,
  globals: window.globals || Object.create(null)
}, {
  $: {
    app: '.root'
  }
}); // eslint-disable-next-line no-underscore-dangle

window[__VARNAME__] = App; // Start
// --------------------------------------------------

App.on('start', function start() {
  // User Agent parser
  var browser = bowser.getParser(window.navigator.userAgent); // const deviceType = browser.getPlatformType(true);

  this.browser = browser; // JavaScript global object

  this.globals = window.taps || {};
}); // Ready
// --------------------------------------------------

App.on('ready', function ready() {
  // Yeah yeah, go FY IE
  if (this.browser.getBrowserName().indexOf('Microsoft Edge') > -1) {
    this.$html.addClass('is-edge');
  }

  if (this.browser.getBrowserName().indexOf('Internet Explorer') > -1) {
    this.$html.addClass('is-ie');
  } // Safari is the new IE, meaning, it's shit


  if (this.browser.getBrowserName().indexOf('Safari') > -1) {
    this.$html.addClass('is-safari');
  } // Svg4Everybody polyfill
  // to support external use of SVG symbols


  Svg4Everybody(); // Object Fit polyfill

  objectFitImages();
  this.emit('dispatch');
}); // Global events
// --------------------------------------------------

App.on({
  resize: function resize(e) {//
  },
  scroll: function scroll(e) {//
  }
}); // Dispatcher & preloader
// --------------------------------------------------

App.on('dispatch', function dispatching() {
  var _this = this;

  var dispatch = this.$dispatcher.dispatch().then(function () {
    return _this.created();
  });
  Promise.all([dispatch]).then(function () {
    setTimeout(function () {
      _this.enter();
    }, 50);
  });
}); // Application lifecycles
// --------------------------------------------------

App.on({
  created: function created() {
    this.$dispatcher.find(Slider, '*[data-component="slider"]');
    this.$dispatcher.find(Carousel, '*[data-component="carousel"]');
    this.$dispatcher.register(Fields, 'fields', this.$body);
  },
  destroy: function destroy() {// Destroy registered components
    // App.destroyComponents();
  },
  enter: function enter() {
    this.$dispatcher.find(Form, '*[data-component*="form"]');
  },
  leave: function leave() {}
});
export default App;

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}