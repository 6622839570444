import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.regexp.to-string.js";

/*----------------------------------------------------*/
// Dependencies

/*----------------------------------------------------*/
import { isElement as isDom, cloneDeep as deepClone, isString } from 'lodash';
/*----------------------------------------------------*/
// Functions

/*----------------------------------------------------*/

/**
 * Improved promises "defer" function
 * @param {prototype} constructor
 */

export function Defer(constructor) {
  var res;
  var rej;
  var isPending = true;
  var isRejected = false;
  var isFulfilled = false;
  var handlers = {
    progress: []
  };
  var promise = new Promise(function (resolve, reject) {
    if (constructor) {
      constructor(resolve, reject);
    }

    res = resolve;
    rej = reject;
  });

  promise.resolve = function (a) {
    res(a);
    isFulfilled = true;
    isPending = false;
    return promise;
  };

  promise.reject = function (a) {
    rej(a);
    isRejected = true;
    isPending = false;
    return promise;
  };

  promise.notify = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    handlers.progress.forEach(function (fn) {
      return fn.apply(void 0, args);
    });
    return promise;
  };

  promise.progress = function (fn) {
    handlers.progress.push(fn);
    return promise;
  }; // status


  promise.isPending = function () {
    return isPending;
  };

  promise.isRejected = function () {
    return isRejected;
  };

  promise.isFulfilled = function () {
    return isFulfilled;
  };

  return promise;
}
/**
 * Clone an object by removing all defined properties and references
 * @param  {Object} [obj={}]
 * @return {Object}
 */

export function flatDataClone() {
  var obj = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return JSON.parse(JSON.stringify(obj));
}
/**
 * Shorthand of lodash clonedeep
 * @param {object|array} object
 */

export function cloneDeep() {
  var object = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Object.create(null);
  return deepClone(object);
}
/**
 * Return the name of a passed function / method
 * @param {callable} fn
 */

export function functionName(fn) {
  var ret = fn.toString();
  ret = ret.substr('function '.length);
  ret = ret.substr(0, ret.indexOf('('));
  return ret;
}
/**
 * Test if passed value is an element (selector, dom, $d, ...)
 * @param {mixed} element
 */

export function isElement(element) {
  if (isDom(element)) {
    return $d(element);
  }

  if (isString(element)) {
    var $element = $d(element);
    return $element.length ? $element : false;
  }

  if (element instanceof $d && element.length) {
    return element;
  }

  return false;
}
/**
 * Fit element to container
 * @param {Element} element
 * @param {Element} container
 * @param {object} object
 */

export function fitTo(element, container, _ref) {
  var width = _ref.width,
      height = _ref.height;
  var $element = $d(element);
  var $container = $d(container);
  var vW = width;
  var vH = height;
  var vR = vW / vH;
  var cW = $container.width();
  var cH = $container.height();
  var cR = cW / cH;
  $element.attr('style', '');

  if (cR >= vR) {
    $element.width(cW);
    var h = cW * (vH / vW);
    $element.height(h);
    $element.css('top', -(h - cH) / 2);
  } else {
    $element.height(cH);
    var w = cH * vR;
    $element.width(w);
    $element.css('left', -(w - cW) / 2);
  }
}
/**
 * Catch next rendered frame from rAF
 */

export function nextFrame(callback) {
  if (typeof callback !== 'undefined') {
    return window.requestAnimationFrame(callback);
  }

  return new Promise(function (res) {
    window.requestAnimationFrame(function () {
      return res();
    });
  });
}
/**
 * Catch breakpoint z-index
 * @param {integer} limit
 */

export function getBreakpointIndex() {
  var limit = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 15;
  var handler = document.body.querySelector('.is-responsive');
  var style = window.getComputedStyle(handler);
  var zIndex = parseInt(style.zIndex, 10);
  return zIndex < limit ? zIndex : false;
}
export default {
  flatDataClone: flatDataClone,
  cloneDeep: cloneDeep,
  functionName: functionName,
  isElement: isElement,
  fitTo: fitTo,
  nextFrame: nextFrame,
  getBreakpointIndex: getBreakpointIndex
};

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}