/**
 * Linear interpolation
 * @param  {Float} value1 starting value
 * @param  {Float} value2 destination value
 * @param  {Float} amount normal value (between 0 and 1)
 * @return {Float}
 */
export function lerp(value1, value2, amount) {
  var amt = amount < 0 ? 0 : amount;
  amt = amt > 1 ? 1 : amt;
  return value1 + (value2 - value1) * amt;
}
/**
 * Smooth step of a value in ranges
 * @param  {Float} min   starting value
 * @param  {Float} max   destination value
 * @param  {Float} value current value in ranges
 * @return {Float}
 */

export function smoothstep(min, max, value) {
  var x = Math.max(0, Math.min(1, (value - min) / (max - min)));
  return x * x * (3 - 2 * x);
}
/**
 * Get a value between 2 values
 * @param  {Float} from  from value
 * @param  {Float} to    to value
 * @param  {Float} value value to compare
 * @return {Float}
 */

export function clamp(from, to, value) {
  if (value < from) return from;
  if (value > to) return to;
  return value;
}
export default {
  lerp: lerp,
  smoothstep: smoothstep,
  clamp: clamp
};

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}