/*----------------------------------------------------*/
// Dependencies

/*----------------------------------------------------*/
import { isElement } from '../core/utils';
import { isInteger } from 'lodash';
import gsap from 'gsap';
/*----------------------------------------------------*/
// Constants & variables

/*----------------------------------------------------*/

var STICKY_HEADER = '.app-header';
/*----------------------------------------------------*/
// Module

/*----------------------------------------------------*/

export default function ScrollTo(element, speed) {
  var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  element = element || 'top';
  speed = typeof speed !== 'undefined' && isInteger(speed) ? speed : 1000;
  var scrollPos = null; // element

  var $element = isElement($d(element));

  if ($element) {
    scrollPos = $element.offset().top;
  } // shorthands


  if (element === 'top') {
    scrollPos = 0;
  } // integer


  if (isInteger(element)) {
    scrollPos = element;
  } // offsets


  if (typeof offset === 'undefined') {
    // Beware of sticky elements
    var offsetHeight = $d(STICKY_HEADER).height();
    scrollPos -= offsetHeight;
  } else if (isInteger(offset)) {
    scrollPos += offset;
  } // limits


  if (scrollPos < 0) {
    scrollPos = 0;
  }

  if (scrollPos !== null) {
    scrollPos = Math.round(scrollPos);
    gsap.to(window, {
      scrollTo: {
        y: scrollPos
      },
      duration: speed / 1000,
      ease: 'power3.inOut'
    });
  }
}

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}