import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _typeof from "@babel/runtime/helpers/typeof";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.regexp.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.function.name.js";

/*----------------------------------------------------*/
// Constants & variables

/*----------------------------------------------------*/
var SKIPPABLE = /file|reset|submit|button|image/i;
var CHECKABLE = /radio|checkbox/i;
var BRACKETS = /(\[[^[\]]*\])/g;
/*----------------------------------------------------*/
// Add custom methods to cash-dom

/*----------------------------------------------------*/

/**
 * Return a collection of elements find from passed selectors
 * @param {Object} selectors
 * @param {boolean} closest
 */

var $select = function $select() {
  var selectors = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var closest = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  if (typeof selectors === 'undefined' || _typeof(selectors) !== 'object') {
    return $d();
  }

  var element = this;
  var elements = Object.create(null);
  Object.entries(selectors).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        k = _ref2[0],
        v = _ref2[1];

    elements[k] = closest === true ? element.closest(v) : element.find(v);
  });
  return elements;
};

$d.fn.$select = $select;
/**
 * Pluck array with passed key
 * @param {array} arr
 * @param {key} prop
 * @param {boolean} deep
 */

function pluck(arr, prop, deep) {
  var plucked = [];
  arr.forEach(function (v) {
    var val = v[prop];

    while (val != null) {
      plucked.push(val);
      if (!deep) break;
      val = val[prop];
    }
  });
  return plucked;
}
/**
 * Get element's value
 * @param {Element} elm
 */


function getValue(elm) {
  if (elm.multiple) {
    return pluck(Array.prototype.filter.call(elm.options, function (option) {
      return option.selected && !option.disabled && !option.parentNode.disabled;
    }), 'value');
  }

  return elm.value || '';
}
/**
 * Parse keys in passed string
 * @param {string} string
 */


function parseKeys(string) {
  var keys = [];
  var prefix = /^([^[\]]*)/;
  var children = new RegExp(BRACKETS);
  var match = prefix.exec(string);

  if (match[1]) {
    keys.push(match[1]);
  }

  match = children.exec(string);

  while (match !== null) {
    keys.push(match[1]);
    match = children.exec(string);
  }

  return keys;
}

function hashAssign(result, keys, value) {
  if (keys.length === 0) {
    result = value;
    return result;
  }

  var key = keys.shift();
  var between = key.match(/^\[(.+?)\]$/);

  if (key === '[]') {
    result = result || [];

    if (Array.isArray(result)) {
      result.push(hashAssign(null, keys, value));
    }

    return result;
  }

  if (!between) {
    result[key] = hashAssign(result[key], keys, value);
  } else {
    var string = between[1];
    var index = +string;

    if (Number.isNaN(index)) {
      result = result || {};
      result[string] = hashAssign(result[string], keys, value);
    } else {
      result = result || [];
      result[string] = hashAssign(result[index], keys, value);
    }
  }

  return result;
}
/**
 * Serialize fields data into an object
 */


function serializeObject() {
  var fields = {};
  this.each(function (i, elm) {
    $d.each(elm.elements || [elm], function (index, el) {
      if ((el.disabled || !el.name || el.tagName === 'FIELDSET' || SKIPPABLE.test(el.type) || CHECKABLE.test(el.type)) && !el.checked) {
        return;
      }

      var value = getValue(el);

      if (typeof value === 'undefined') {
        return;
      }

      if (el.name.match(BRACKETS)) {
        var keys = parseKeys(el.name);
        hashAssign(fields, keys, value);
      } else {
        fields[el.name] = value;
      }
    });
  });
  return fields;
}

$d.fn.serializeObject = serializeObject;

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}