/*----------------------------------------------------*/
// Dependencies

/*----------------------------------------------------*/
import 'core-js/stable';
import 'regenerator-runtime/runtime'; // app entry

import App from './app/app';
/*----------------------------------------------------*/
// Bootstrap

/*----------------------------------------------------*/

document.addEventListener('DOMContentLoaded', function () {
  App.ready();
}); // console.log(App);

App.start();

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}