/*----------------------------------------------------*/
// Dependencies

/*----------------------------------------------------*/
import 'raf/polyfill'; // setTimeout with rAF equivalent
// --------------------------------------------------

if (!window.requestTimeout) {
  window.requestTimeout = function requestTimeout(fn, delay) {
    if (!window.requestAnimationFrame) {
      return window.setTimeout(fn, delay);
    }

    var start = new Date().getTime();
    var handle = {};

    function loop() {
      var current = new Date().getTime();
      var delta = current - start;

      if (delta >= delay) {
        fn.call();
      } else {
        handle.value = window.requestAnimationFrame(loop);
      }
    }

    handle.value = window.requestAnimationFrame(loop);
    return handle;
  };
}

if (!window.clearRequestTimeout) {
  window.clearRequestTimeout = function clearRequestTimeout(handle) {
    if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(handle.value);
    } else {
      window.clearTimeout(handle);
    }
  };
} // setInterval with rAF equivalent
// --------------------------------------------------


if (!window.requestInterval) {
  window.requestInterval = function requestInterval(fn, delay) {
    if (!window.requestAnimationFrame) {
      return window.setInterval(fn, delay);
    }

    var start = new Date().getTime();
    var handle = {};

    function loop() {
      var current = new Date().getTime();
      var delta = current - start;

      if (delta >= delay) {
        fn.call();
        start = new Date().getTime();
      }

      handle.value = window.requestAnimationFrame(loop);
    }

    handle.value = window.requestAnimationFrame(loop);
    return handle;
  };
}

if (!window.clearRequestInterval) {
  window.clearRequestInterval = function clearRequestInterval(handle) {
    if (window.cancelAnimationFrame) {
      window.cancelAnimationFrame(handle.value);
    } else {
      window.clearInterval(handle);
    }
  };
}

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}