import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.entries.js";

/*----------------------------------------------------*/
// Export all available controllers

/*----------------------------------------------------*/
import { camelCase } from 'lodash';
/*----------------------------------------------------*/
// Methods

/*----------------------------------------------------*/

var modules = Object.create(null);

function install(module) {
  var controllers = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  Object.entries(controllers).forEach(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        name = _ref2[0],
        fn = _ref2[1];

    module.modules[name] = fn;
  });
  this.$controllers = module;
}

export function get(controller) {
  var name = camelCase(controller);
  return this.modules[name] || false;
}
export default {
  modules: modules,
  install: install,
  get: get
};

if (module.hot) {
  module.hot.accept(function (err) {
    if (err) {
      console.error(err);
    }
  });
}